import {api} from '../api';
import {keyBy} from 'lodash';

export const TASK__TASKS_RECEIVED = 'TASK__TASKS_RECEIVED';
export const TASK__ERROR = 'TASK__ERROR';
export const TASK__START_FETCH = 'TASK__START_FETCH';
export const TASK__TASK_TYPES_RECEIVED = 'TASK__TASK_TYPES_RECEIVED';
export const TASK__TASK_UPDATED = 'TASK__TASK_UPDATED';
export const TASK__TASK_ADDED = 'TASK__TASK_ADDED';
export const TASK__TASK_REMOVED = 'TASK__TASK_REMOVED';
export const TASK__RUNNING_JOBS_RECEIVED = 'TASK__RUNNING_JOBS_RECEIVED';
export const TASK__RUNNING_JOB_UPDATED = 'TASK__RUNNING_JOB_UPDATED';

export const getTasks = (params = {}) => async (dispatch) => {
	try {
		dispatch({type: TASK__START_FETCH});
		const response = await api.get('/tasks', {params});
		dispatch({
			type: TASK__TASKS_RECEIVED,
			payload: {
				data: keyBy(response.data, 'ID'),
				order: response.data.map((task) => task.ID),
			},
		});
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

export const getQueuedAndRunningJobs = () => async (dispatch, getState) => {
	try {
		const response = await api.get('/tasks/byJobState', {
			params: {states: ['active', 'inactive']},
		});
		let taskMap = {};
		for (let task of response.data) {
			if (task.Job.type === 'CHILD') {
				const runnableID = getRunnableID(task, getState());

				if (!taskMap[task.TaskTypeID]) {
					taskMap[task.TaskTypeID] = {};
				}
				if (!taskMap[task.TaskTypeID][task.RevisionID]) {
					taskMap[task.TaskTypeID][task.RevisionID] = {};
				}
				taskMap[task.TaskTypeID][task.RevisionID][runnableID] = true;
			}
		}
		dispatch({type: TASK__RUNNING_JOBS_RECEIVED, payload: taskMap});
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

export const updateQueuedOrRunningJob = (taskTypeID, revisionID, runnableID, running) => async (dispatch) => {
	try {
		dispatch({
			type: TASK__RUNNING_JOB_UPDATED,
			payload: {
				taskTypeID,
				revisionID,
				runnableID,
				running,
			},
		});
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

export const updateQueuedOrRunningJobByName = (task, running) => async (dispatch, getState) => {
	try {
		let runnableID = getRunnableID(task, getState());
		dispatch({
			type: TASK__RUNNING_JOB_UPDATED,
			payload: {
				taskTypeID: task.TaskTypeID,
				revisionID: task.RevisionID,
				runnableID,
				running,
			},
		});
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

function getRunnableID(task, state) {
	// these are for runnables that don't have actual runnableIDs in hydra
	// Aggregate, Forecasts, and Transforms all use hardcoded "fake" runnableIDs
	// defined in the reducers to make them easily compatible with the other Run code
	// Below, I am grabbing the fake runnableIDs from the reducers

	let runnableID;

	// Aggregate
	if (task.TaskTypeID === 7) {
		runnableID = state.aggregates.data[task.Description];
		// Forecast
	} else if (task.TaskTypeID === 5) {
		runnableID = state.forecast.run.data[task.Description];
		// Transform
	} else if (task.TaskTypeID === 8) {
		runnableID = state.transforms.data[task.Description];
		// Normal runnables
	} else {
		runnableID = task.RunnableID;
	}
	return runnableID;
}

export const createTask = (task) => (dispatch) => {
	dispatch({type: TASK__TASK_ADDED, payload: task});
};

export const updateTask = (task) => (dispatch) => {
	dispatch({type: TASK__TASK_UPDATED, payload: {[task.ID]: task}});
};

export const removeTask = (taskID) => (dispatch) => {
	dispatch({type: TASK__TASK_REMOVED, payload: taskID});
};

export const getTaskTypes = () => async (dispatch) => {
	try {
		const response = await api.get('/tasks/types');
		dispatch({
			type: TASK__TASK_TYPES_RECEIVED,
			payload: keyBy(response.data, 'ID'),
		});
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

export const removeJob = (jobID) => async (dispatch) => {
	try {
		await api.delete(`/jobs/${jobID}`);
	} catch (err) {
		dispatch({type: TASK__ERROR});
	}
};

export const checkTasksCompletion = (revisionID) => async (dispatch) => {
	try {
		const response = await api.get('/tasks', {
			params: {
				TaskTypeID: 8,
				Description: 'Analyst',
				RevisionID: revisionID,
			},
		});
		const tasks = response.data;
		const hasCompletedTask = tasks.some(task => task.CompletedAt);
		return hasCompletedTask;
	} catch (err) {
		dispatch({type: TASK__ERROR});
		return false;
	}
};
