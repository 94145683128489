import {
	FORECAST__INDICATORS_RECEIVED,
	FORECAST__MARKETS_RECEIVED,
	FORECAST__DATAPOINTS_RECEIVED,
	FORECAST__ASSIGNMENTS_RECEIVED,
	FORECAST__MARKET_INDICATORS_RECEIVED,
	FORECAST__NOTES_RECEIVED,
	FORECAST__NOTE_RECEIVED,
	FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
	FORECAST__START_FORECAST,
	FORECAST__START_SAVE,
	FORECAST__SAVE_FINISHED,
	FORECAST__MODIFIED,
	FORECAST__FORECAST_SUCCESS,
	FORECAST__ENABLE_SAVE,
	FORECAST__DISABLE_SAVE,
	FORECAST__BEGIN_FETCH,
	FORECAST__BEGIN_FETCH_DEPENDENCIES,
	FORECAST__FETCH_SUCCESS,
	FORECAST__SAVE_STATE_RECEIVED,
	FORECAST__ADDING_NOTE,
	FORECAST__DEPENDENCY_INDICATORS_RECEIVED,
	FORECAST__DEPENDENCIES_START_SAVE,
	FORECAST__DEPENDENCIES_SAVE_FINISHED,
	FORECAST__MARKET_INDICATOR_UPDATED,
	FORECAST__SHOW_DATA_EDITOR,
} from '../actions/forecast';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	isFetching: false,
	isFetchingDependencies: false,
	indicators: {},
	markets: {},
	datapoints: {},
	datapointOverrides: {},
	dependencyIndicators: {},
	assignments: [],
	marketIndicators: {},
	isForecasting: false,
	forecasted: false,
	isSaving: false,
	isSavingDependencies: false,
	canSave: false,
	savedAt: null,
	notes: {},
	noteOrder: [],
	isAddingNote: false,
	run: {
		data: {
			1: {
				ID: 1,
				Name: 'Analyst',
			},
			2: {
				ID: 2,
				Name: 'Subnational',
			},
			3: {
				ID: 3,
				Name: 'All',
			},
			Analyst: 1,
			Subnational: 2,
			All: 3,
		},
		order: [1, 2, 3],
	},
	isDataReady: false,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case FORECAST__BEGIN_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case FORECAST__BEGIN_FETCH_DEPENDENCIES:
			return {
				...state,
				isFetchingDependencies: true,
			};
		case FORECAST__FETCH_SUCCESS:
			return {
				...state,
				isFetching: false,
			};
		case FORECAST__INDICATORS_RECEIVED:
			return {
				...state,
				indicators: {
					...state.indicators,
					...action.payload,
				},
			};
		case FORECAST__MARKETS_RECEIVED:
			return {
				...state,
				markets: {
					...state.markets,
					...action.payload,
				},
			};
		case FORECAST__DATAPOINTS_RECEIVED:
			return {
				...state,
				datapoints: {
					...state.datapoints,
					...action.payload,
				},
			};
		case FORECAST__FORECASTED_DATAPOINTS_RECEIVED:
			return {
				...state,
				datapointOverrides: {
					...state.datapointOverrides,
					...action.payload,
				},
			};
		case FORECAST__START_FORECAST:
			return {
				...state,
				isForecasting: true,
			};
		case FORECAST__ASSIGNMENTS_RECEIVED:
			return {
				...state,
				assignments: action.payload,
			};
		case FORECAST__MARKET_INDICATORS_RECEIVED:
			return {
				...state,
				marketIndicators: {
					...state.marketIndicators,
					...action.payload,
				},
			};
		case FORECAST__MARKET_INDICATOR_UPDATED:
			return {
				...state,
				marketIndicators: {
					...state.marketIndicators,
					[action.payload.marketID]: {
						...state.marketIndicators[action.payload.marketID],
						[action.payload.indicatorID]: {
							SavedAt: new Date(),
						},
					},
				},
			};
		case FORECAST__NOTES_RECEIVED:
			return {
				...state,
				notes: {
					...state.notes,
					...action.payload,
				},
			};
		case FORECAST__NOTE_RECEIVED:
			return {
				...state,
				notes: {...state.notes, ...action.payload},
				isAddingNote: false,
			};
		case FORECAST__START_SAVE:
			return {
				...state,
				isSaving: true,
			};
		case FORECAST__SAVE_FINISHED:
			return {
				...state,
				isSaving: false,
				canSave: false,
			};
		case FORECAST__SAVE_STATE_RECEIVED:
			return {
				...state,
				savedAt: action.payload,
			};
		case FORECAST__MODIFIED:
			return {
				...state,
				savedAt: 'modified',
				forecasted: false,
			};
		case FORECAST__FORECAST_SUCCESS:
			return {
				...state,
				savedAt: 'modified',
				isForecasting: false,
				forecasted: true,
			};
		case FORECAST__ENABLE_SAVE:
			return {
				...state,
				canSave: true,
			};
		case FORECAST__DISABLE_SAVE:
			return {
				...state,
				canSave: false,
			};
		case FORECAST__ADDING_NOTE:
			return {
				...state,
				isAddingNote: true,
			};
		case FORECAST__DEPENDENCY_INDICATORS_RECEIVED:
			return {
				...state,
				dependencyIndicators: {
					...state.dependencyIndicators,
					...action.payload,
				},
				isFetchingDependencies: false,
			};
		case FORECAST__DEPENDENCIES_START_SAVE:
			return {
				...state,
				isSavingDependencies: true,
			};
		case FORECAST__DEPENDENCIES_SAVE_FINISHED:
			return {
				...state,
				isSavingDependencies: false,
			};
		case FORECAST__SHOW_DATA_EDITOR:
			return {
				...state,
				isDataReady: action.payload,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
