import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';
import {Form, Button} from 'react-bootstrap';
import * as yup from 'yup';
import requireAuth from '../auth/requireAuth';
import {DataAdminRole} from '../util/entitlements';
import * as actions from '../../actions/throughput';

const Throughput = (props) => {
    const throughput = useSelector((state) => state.throughput.data);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const {getThroughput, updateThroughput} = props;

    const [formValues, setFormValues] = useState({
        PROCESSING: {read: '', write: ''},
        DATAPOINTS: {read: '', write: ''},
    });

    const [errors, setErrors] = useState({
        PROCESSING: {read: '', write: ''},
        DATAPOINTS: {read: '', write: ''},
    });

    const [isValid, setIsValid] = useState({
        PROCESSING: false,
        DATAPOINTS: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await getThroughput('PROCESSING');
            await getThroughput('DATAPOINTS');
            setIsLoading(false);
        };
        fetchData();
    }, [getThroughput]);

    useEffect(() => {
        if (throughput) {
            setFormValues({
                PROCESSING: {
                    read: throughput.PROCESSING.readCapacityUnits,
                    write: throughput.PROCESSING.writeCapacityUnits,
                },
                DATAPOINTS: {
                    read: throughput.DATAPOINTS.readCapacityUnits,
                    write: throughput.DATAPOINTS.writeCapacityUnits,
                },
            });
        }
    }, [throughput]);

    const validationSchema = (tableType) => {
        return yup.object().shape({
            read: yup
                .number()
                .required('Read capacity is required.')
                .min(tableType === 'PROCESSING' ? 1 : 50, `Read capacity must be at least ${tableType === 'PROCESSING' ? 1 : 50}.`)
                .max(100, 'Read capacity must be at most 100.'),
            write: yup
                .number()
                .required('Write capacity is required.')
                .min(1, 'Write capacity must be at least 1.')
                .max(5000, 'Write capacity must be at most 5000.'),
        });
    };

    const validateForm = async (values, tableType) => {
        try {
            await validationSchema(tableType).validate(values, {abortEarly: false});
            return {};
        } catch (err) {
            const validationErrors = {};
            err.inner.forEach((error) => {
                validationErrors[error.path] = error.message;
            });
            return validationErrors;
        }
    };

    const handleChange = async (e, tableType) => {
        const {name, value} = e.target;
        const newFormValues = {
            ...formValues,
            [tableType]: {
                ...formValues[tableType],
                [name]: value,
            },
        };
        setFormValues(newFormValues);

        const validationErrors = await validateForm(newFormValues[tableType], tableType);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [tableType]: validationErrors,
        }));
        setIsValid((prevIsValid) => ({
            ...prevIsValid,
            [tableType]: Object.keys(validationErrors).length === 0,
        }));
    };

    const handleBlur = async (e, tableType) => {
        const {name, value} = e.target;
        const newFormValues = {
            ...formValues,
            [tableType]: {
                ...formValues[tableType],
                [name]: value,
            },
        };
        setFormValues(newFormValues);

        const validationErrors = await validateForm(newFormValues[tableType], tableType);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [tableType]: validationErrors,
        }));
        setIsValid((prevIsValid) => ({
            ...prevIsValid,
            [tableType]: Object.keys(validationErrors).length === 0,
        }));
    };

    const handleSubmit = async (e, tableType) => {
        e.preventDefault();
        const validationErrors = await validateForm(formValues[tableType], tableType);
        if (Object.keys(validationErrors).length === 0) {
            if (window.confirm('Are you sure you want to update the throughput?')) {
                setIsUpdating(true);
                await updateThroughput({...formValues[tableType], tableType});
                setIsUpdating(false);
            }
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [tableType]: validationErrors,
            }));
        }
    };

    const renderForm = (tableType) => (
        <div>
            <h2>{tableType} Throughput Management</h2>
            <Form onSubmit={(e) => handleSubmit(e, tableType)}>
                <Form.Group controlId={`${tableType}-read`}>
                    <Form.Label>Read Capacity</Form.Label>
                    <Form.Control
                        type="number"
                        name="read"
                        value={formValues[tableType].read}
                        onChange={(e) => handleChange(e, tableType)}
                        onBlur={(e) => handleBlur(e, tableType)}
                        isInvalid={!!errors[tableType].read}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[tableType].read}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        {tableType === 'PROCESSING'
                            ? 'The read throughput range is from 1 to 100.'
                            : 'The read throughput range is from 50 to 100.'}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId={`${tableType}-write`}>
                    <Form.Label>Write Capacity</Form.Label>
                    <Form.Control
                        type="number"
                        name="write"
                        value={formValues[tableType].write}
                        onChange={(e) => handleChange(e, tableType)}
                        onBlur={(e) => handleBlur(e, tableType)}
                        isInvalid={!!errors[tableType].write}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[tableType].write}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        The write throughput range is from 1 to 5000.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId={`${tableType}-decreases`}>
                    <Form.Label>Number of Decreases Today</Form.Label>
                    <Form.Control
                        type="text"
                        readOnly
                        value={throughput[tableType].numberOfDecreasesToday}
                    />
                    {throughput[tableType].numberOfDecreasesToday === 3 && (
                        <Form.Text className="text-danger">
                            This is the last throughput decrease allowed today, please double check that you understand the consequences of proceeding. This can be a very costly mistake if done improperly.
                        </Form.Text>
                    )}
                </Form.Group>
                <Button variant="secondary" onClick={() => getThroughput(tableType)}>
                    Reset
                </Button>
                <Button variant="primary" type="submit" disabled={!isValid[tableType]}>
                    Update
                </Button>
            </Form>
        </div>
    );

    if (isLoading || isUpdating) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {renderForm('PROCESSING')}
            {renderForm('DATAPOINTS')}
        </div>
    );
};

export default compose(
    connect(null, {...actions}),
    requireAuth(DataAdminRole)
)(Throughput);